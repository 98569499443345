<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if='error' class='justify-content-around pt-4'>
      <mdb-col md='8'>
        <mdb-alert color='danger' >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <Admintopbar :breadcrumb='breadcrumb' />
    <mdb-row class="pt-4">
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="grey accent-3">Listing</mdb-card-header>
          <mdb-card-body>
            <mdb-input class="mt-0" v-model="search" label="Search" />
            <mdb-select v-model="selectedSkills" label="Select Skills" :multiple="true" :taggable="true" :visible-options="10" style="width: 50%;"/>
            <mdb-col class="col-6" style="display: flex;">
              Only Employees in probation
              <mdb-switch v-model="showInProbationEmployees" offLabel="" onLabel="" />
            </mdb-col>
            <mdb-collapse
              :toggleTag="['button']"
              :togglers="2"
              :toggleClass="['btn btn-sm btn-primary']"
              :toggleText="['Time filter']"
            >
              <mdb-col>
                <mdb-input v-model="from" type="date" label="From" /><br />
              </mdb-col>
              <mdb-col>
                <mdb-input v-model="to" type="date" label="To" /><br />
              </mdb-col>
            </mdb-collapse>
            <br/>
            <mdb-btn color="warning" v-on:click="resetForm" size="sm" class="mt-0 mb-3">Reset Search</mdb-btn>
            <mdb-datatable-2 v-model='rowsToShow' @selected='chooseEmployee($event)' selectable :searching="search" v-if="employees.rows.length" />
            <mdb-btn outline="success" v-on:click="addNewEmployee" class="float-right">New employee</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="mdb-color darken-1">Details</mdb-card-header>
          <mdb-card-body>
            <div v-if="!currentemployee" class="alert alert-info">
              Choose an employee
            </div>
            <div v-else>
              <mdb-row v-if="employeeformerror">
                <mdb-col md="12">
                  <div class="alert alert-danger">{{employeeformerror}}</div>
                </mdb-col>
              </mdb-row>
              <mdb-row v-if="employeeformsuccess">
                <mdb-col md="12">
                  <div class="alert alert-success">{{employeeformsuccess}}</div>
                </mdb-col>
              </mdb-row>
              <mdb-row v-if="newemployeemode">
                <mdb-col>
                  <h4>New Employee</h4>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <mdb-container class="bg-danger p-2 text-center" style="color:white;" v-if="currentemployee.deleted_at">EMPLOYEE DELETED</mdb-container>
                  <mdb-btn color="danger" v-on:click="showconfirmdeleteemployeemodal=true" size="sm" class="float-right" v-if="!newemployeemode && !currentemployee.deleted_at">Delete</mdb-btn>
                </mdb-col>
                <mdb-col>
                  <mdb-btn v-if="currentemployee && !currentemployee.deleted_at" color="secondary"  @click="confirmresetnda = true" size="sm" class="float-right" >Reset NDA contract</mdb-btn>
                </mdb-col>
                <mdb-col v-if="!newemployeemode">
                  <mdb-btn color="primary" v-on:click="$router.push(`/admin/employees/${currentemployee.id}/history`)" size="sm" class="float-right">Task History</mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="6">
                  <mdb-input label="Firstname" v-model="currentemployee.first_name" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input label="Lastname" v-model="currentemployee.last_name" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input label="Nickname" v-model="currentemployee.nickname" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-select v-model="currentemployee.accesslevel" label="Access level" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input label="Recruiting time" v-model="currentemployee.added_at" readOnly />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input label="Email" v-model="currentemployee.email" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input label="Cell" v-model="currentemployee.cell" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input label="Region" v-model="currentemployee.region" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input label="How to pay" v-model="currentemployee.howtopay" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input type="checkbox" label="In probation" id="chk_prob" v-model="currentemployee.in_probation" />
                </mdb-col>
                <mdb-col md="6">
                  <mdb-input type="checkbox" label="Nda Signed" id="chk_nda" :value="currentemployee.nda_signed_at !== null" />
                </mdb-col>
                <mdb-col md="12">
                  <mdb-input type="textarea" label="Notes" :rows="5" v-model="currentemployee.notes" outline />
                </mdb-col>
                <mdb-col md="12">
                  <hr />
                  <h3>Skills</h3>
                  <mdb-input v-for="(item,index) in currentemployee.skillsformdata" v-bind:key="index" :id="`sk_${index}`" v-model="currentemployee.skillsformdata[index].selected" type="checkbox" :label="item.code" />
                </mdb-col>
                <mdb-col md="12" v-if="currentemployee && currentemployee.photo_id">
                  <hr />
                  <mdb-container>
                    <label for="photoid">Photo ID</label>
                    <img id="photoid" :src="currentemployee.photo_id" class="img-fluid">
                    <a :href="currentemployee.photo_id" target="_blank">If you cannot see image, click here to download it</a>
                  </mdb-container>
                </mdb-col>
                <mdb-col md="12">
                  <hr />
                  <h3>Tasks in process</h3>
                  <mdb-tbl>
                    <mdb-tbl-head>
                      <tr>
                        <th style="font-weight: bold;">Id</th>
                        <th style="font-weight: bold;">Task name</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body class="main">
                      <tr v-for="(task,index) in taskHistory" v-bind:key="index">
                        <td>{{task.taskid}}</td>
                        <td>{{task.name}}</td>
                      </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
                </mdb-col>
              </mdb-row>
              <hr />
              <mdb-row v-if="!(newemployeemode && employeeformsuccess)">
                <mdb-col md="12">
                  <mdb-btn v-on:click="newemployeemode?savenewemployee():editemployee()" outline="success" class="float-right">Save</mdb-btn>
                </mdb-col>
              </mdb-row>
            </div>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
    <mdb-modal :show="showconfirmdeleteemployeemodal" @close="showconfirmdeleteemployeemodal=false" danger>
      <mdb-modal-header>
        <mdb-modal-title class="white-text">Are you sure?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body v-if="currentemployee">
        <h3>Are you sure you want to delete the employee <strong>{{currentemployee.first_name}} {{currentemployee.last_name}}</strong>?</h3>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="danger" v-on:click="deleteemployee()">Delete</mdb-btn>
        <mdb-btn outline="elegant" v-on:click="showconfirmdeleteemployeemodal=false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal v-if="confirmresetnda" @close="confirmresetnda = false" size="sm" class="text-center" warning>
      <mdb-modal-header center :close="false">
        <p class="heading">Are you sure you want to reset nda this employee?</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-icon icon="times" size="4x" class="animated rotateIn"/>
        <p>A notification email will be sent to {{currentemployee.email}}</p>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="warning" @click="resetNda">Yes</mdb-btn>
        <mdb-btn color="warning" @click="confirmresetnda = false">No</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbCollapse,
  mdbDatatable2,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbSelect,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbModalTitle,
  mdbIcon,
  mdbSwitch,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'Home',
  components: {
    Header,
    Admintopbar,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCol,
    mdbCollapse,
    mdbDatatable2,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbSelect,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbModalTitle,
    mdbIcon,
    mdbSwitch,
  },
  data() {
    return {
      error: '',
      employeeformerror: '',
      employeeformsuccess: '',
      dummy: '',
      search: '',
      currentemployee: null,
      newemployeemode: false,
      showInProbationEmployees: false,
      from: null,
      to: null,
      availableskills: [],
      selectedSkills: [],
      taskHistory: [],
      breadcrumb: [
        {
          title: 'Employees',
          href: '/admin/employees',
        },
        {
          title: 'List',
          href: null,
        },
      ],
      employees: {
        columns: [
          {
            label: 'First name',
            field: 'first_name',
            sort: true,
          },
          {
            label: 'Last name',
            field: 'last_name',
            sort: true,
          },
          {
            label: 'Nickname',
            field: 'nickname',
            sort: true,
          },
          {
            label: 'Email',
            field: 'email',
            sort: true,
          },
          {
            label: 'Completed tasks',
            field: 'numberOfCompletedTasks',
            sort: true,
          },
          {
            label: 'Completed tasks in the last 7 days',
            field: 'numberOfCompletedTasksLast7Days',
            sort: true,
          },
          {
            label: 'Deleted',
            field: 'deleted_at',
            sort: true,
          },
          {
            label: 'In Probation',
            field: 'in_probation',
            sort: true,
          },
          {
            label: 'Recruited at',
            field: 'added_at',
            sort: true,
          },
          {
            label: 'Access level',
            field: 'accesslevel',
            sort: true,
          },
        ],
        rows: [],
      },
      showconfirmdeleteemployeemodal: false,
      confirmresetnda: false,
    };
  },
  mounted() {
    this.loadAvailableSkills();
    this.loadAllEmployees();
    this.addNewEmployee();

    if (this.$route.params.employeeid) {
      this.chooseEmployee({ id: this.$route.params.employeeid });
    }
  },
  computed: {
    rowsToShow() {
      const filters = {
        from: this.from,
        to: this.to,
        showInProbationEmployees: this.showInProbationEmployees,
        selectedSkillCodes: this.selectedSkills.filter((skill) => skill.selected).map((skill) => skill.value),
      };
      let filteredRows = this.employees.rows;
      if (filters.from) {
        filteredRows = filteredRows.filter((el) => el.added_at.split(' ')[0] >= filters.from);
      }

      if (filters.to) {
        filteredRows = filteredRows.filter((el) => el.added_at.split(' ')[0] <= filters.to);
      }

      if (filters.showInProbationEmployees) {
        filteredRows = filteredRows.filter((el) => el.in_probation === 'YES');
      }

      if (filters.selectedSkillCodes && filters.selectedSkillCodes.length > 0) {
        filteredRows = filteredRows.filter((employee) => {
          const employeeSkillsArray = employee.skills.split(' ');
          return filters.selectedSkillCodes.every((skillCode) => employeeSkillsArray.includes(skillCode));
        });
      }

      return {
        columns: this.employees.columns,
        rows: filteredRows,
      };
    },
  },
  methods: {
    loadAllEmployees() {
      const self = this;
      const api = new Apicall();
      self.employees.rows = [];
      api.call('GET', 'api/adminemployee/list').then((response) => {
        if (response.status === 'OK') {
          self.employees.rows = response.data;

          if (this.$route.query.id) {
            self.chooseEmployee({ id: this.$route.query.id });
          }
        } else {
          self.error = response.msg;
        }
      });
    },
    resetForm() {
      this.from = null;
      this.to = null;
      this.showInProbationEmployees = false;
      this.search = '';
      this.selectedSkillsMethod();
    },
    selectedSkillsMethod() {
      this.selectedSkills = this.availableskills?.map((skill) => ({
        text: skill.code,
        value: skill.code,
      }));
    },
    nl2br(str) {
      if (str) {
        return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
      }
      return '';
    },
    loadAvailableSkills() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/admintaskconfig/skills-list').then((response) => {
        if (response.status === 'OK') {
          self.availableskills = response.data;
          this.selectedSkillsMethod();
        } else {
          self.error = response.msg;
        }
      });
    },
    chooseEmployee(ev) {
      this.newemployeemode = false;
      this.currentemployee = null;
      this.employeeformsuccess = '';
      this.employeeformerror = '';
      if (ev) {
        const api = new Apicall();
        const self = this;
        this.currentemployee = null;
        api.call('POST', 'api/adminemployee/get-one', { employeeid: ev.id }).then((response) => {
          if (response.status === 'OK') {
            self.employeeformerror = '';
            self.currentemployee = response.data;
            self.ajustCurrentEmployeeData();
            self.loadTaskHistory();
            const rootEle = document.documentElement;
            rootEle.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            self.error = response.msg;
          }
        });
      }
    },
    ajustCurrentEmployeeData() {
      this.currentemployee.in_probation = this.currentemployee.in_probation === '1';
      this.currentemployee.accesslevel = [
        {
          value: 'EMPLOYEE',
          text: 'EMPLOYEE',
          selected: this.currentemployee.accesslevel === 'EMPLOYEE',
        }, {
          value: 'FULLACCESS',
          text: 'FULLACCESS',
          selected: this.currentemployee.accesslevel === 'FULLACCESS',
        },
      ];
      this.currentemployee.skillsformdata = [];
      this.availableskills.forEach((i) => {
        this.currentemployee.skillsformdata.push({ code: i.code, selected: this.currentEmployeeHasThisSkill(i.code) });
      });
    },
    loadTaskHistory() {
        const self = this;
        const api = new Apicall();
        self.error = '';
        api.call('POST', 'api/adminemployee/get-ongoing-tasks', { employeeid: this.currentemployee.id }).then((response) => {
          if (response.status === 'OK') {
            self.taskHistory = response.data;
          } else {
            self.error = response.msg;
          }
        });
    },
    currentEmployeeHasThisSkill(skillcode) {
      return this.currentemployee.skills.split(' ').indexOf(skillcode) >= 0;
    },
    deleteemployee() {
      const api = new Apicall();
      const self = this;
      api.call('POST', 'api/adminemployee/delete-one', { employeeid: this.currentemployee.id }).then((response) => {
        if (response.status === 'ERROR') {
          self.error = response.msg;
        }
        self.showconfirmdeleteemployeemodal = false;
        self.loadAllEmployees();
        self.currentemployee = null;
      });
    },
    editemployee() {
      const self = this;
      self.employeeformsuccess = '';
      self.employeeformerror = '';
      const api = new Apicall();
      api.call('POST', 'api/adminemployee/edit', this.currentemployee).then((response) => {
        if (response.status === 'OK') {
          self.employeeformsuccess = 'Employee updated sucessfully';
        } else {
          self.employeeformerror = response.msg;
        }
        self.loadAllEmployees();
        const rootEle = document.documentElement;
        rootEle.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    },
    addNewEmployee() {
      this.newemployeemode = true;
      this.employeeformsuccess = '';
      this.employeeformerror = '';
      this.currentemployee = {
        skills: '',
        accesslevel: 'EMPLOYEE',
        in_probation: '1',
      };
      this.ajustCurrentEmployeeData();
      const rootEle = document.documentElement;
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    savenewemployee() {
      const self = this;
      self.employeeformsuccess = '';
      self.employeeformerror = '';
      const api = new Apicall();
      api.call('POST', 'api/adminemployee/add', this.currentemployee).then((response) => {
        if (response.status === 'OK') {
          self.employeeformsuccess = 'Employee added sucessfully';
        } else {
          self.employeeformerror = response.msg;
        }
        self.loadAllEmployees();
        const rootEle = document.documentElement;
        rootEle.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    },
    resetNda() {
      const api = new Apicall();
      api.call('POST', 'api/employee/send-nda', { email: this.currentemployee.email }).then((response) => {
        this.confirmresetnda = false;
        if (response.status === 'OK') {
          const sendndasuccess = `We just sent a NDA contract to sign by email at ${this.currentemployee.email}. Reset NDA contract successfully.`;
          this.notificationSusscess(sendndasuccess);
          this.chooseEmployee(this.currentemployee);
        } else {
          this.notificationError(response.msg);
        }
      }).catch((error) => {
        this.confirmresetnda = false;
        this.notificationError(error);
      });
    },
    notificationSusscess(_messsage) {
      this.$notify.success({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
    notificationError(_messsage) {
      this.$notify.error({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
  },
};
</script>
