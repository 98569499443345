import { render, staticRenderFns } from "./Root.vue?vue&type=template&id=f271f300&scoped=true&"
import script from "./Root.vue?vue&type=script&lang=js&"
export * from "./Root.vue?vue&type=script&lang=js&"
import style0 from "./Root.vue?vue&type=style&index=0&id=f271f300&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f271f300",
  null
  
)

export default component.exports