<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if='error' class='justify-content-around pt-4'>
      <mdb-col md='8'>
        <mdb-alert color='danger' >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <mdb-row v-if='success' class='justify-content-around pt-4'>
      <mdb-col md='8'>
        <mdb-alert color='success' >Item Added successfully</mdb-alert>
      </mdb-col>
    </mdb-row>
    <Admintopbar :breadcrumb='breadcrumb' />
    <mdb-row class="pt-4">
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="grey accent-3">Skills list</mdb-card-header>
          <mdb-card-body class="tablewithcursor">
            <mdb-input class="mt-0" v-model="searchSkill" label="Search" />
            <mdb-datatable-2 v-model='skills' :searching="searchSkill" v-if="skills.rows.length" @selected="showSkillOptions($event)" />
            <mdb-btn outline="success" v-on:click="showAddSkill" class="float-right">New skill</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="mdb-color darken-1">Task types list</mdb-card-header>
          <mdb-card-body class="tablewithcursor">
            <mdb-input class="mt-0" v-model="searchTasktype" label="Search" />
            <mdb-datatable-2 v-model='tasktype' :searching="searchTasktype" v-if="tasktype.rows.length" @selected="showTasktypeOptions($event)" />
            <mdb-btn outline="success" v-on:click="showAddTaskType" class="float-right">New task type</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
    <mdb-row class="mt-3">
      <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="grey accent-3">Taskqueue templates list</mdb-card-header>
            <mdb-card-body class="tablewithcursor">
              <mdb-input class="mt-0" v-model="searchTasktemplate" label="Search" />
              <mdb-datatable-2 v-model="taskTemplateRows" :searching="searchTasktemplate" v-if="taskTemplateRows.rows.length" @selected="$router.push(`/admin/tasks-configs/template?templateId=${$event.id}`)" />
              <mdb-btn outline="success" @click="$router.push('/admin/tasks-configs/template')" class="float-right">New template</mdb-btn>
            </mdb-card-body>
          </mdb-card>
      </mdb-col>
    </mdb-row>
    <mdb-modal :show="addSkillModal" @close="addSkillModal = false" info size="lg">
      <mdb-modal-header>
        <mdb-modal-title>New skill</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row v-if='modalerror' class='justify-content-around pt-4'>
          <mdb-col md='12'>
            <mdb-alert color='danger' >{{modalerror}}</mdb-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="newskill.code" label="Code" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="newskill.name" label="Name" />
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <div>
          <mdb-btn color="primary" v-on:click="saveNewSkill">Save</mdb-btn>
          <mdb-btn outline="info" @click="addSkillModal=false;">Close</mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="addTaskTypeModal" @close="addTaskTypeModal = false" info size="lg">
      <mdb-modal-header>
        <mdb-modal-title>New task type</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row v-if='modalerror' class='justify-content-around pt-4'>
          <mdb-col md='12'>
            <mdb-alert color='danger' >{{modalerror}}</mdb-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="newtasktype.code" label="Code" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="newtasktype.name" label="Name" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <h3>Payable units (optional)</h3>
            <select v-model="newtasktype.remun_unit">
              <option value="">None</option>
              <option value="MIN">Minutes</option>
              <option value="DAY">Days</option>
            </select>
            <mdb-input v-model="newtasktype.remun_unit_nb" type="number" label="Number of units (OPTIONAL)" />
            <mdb-input v-model="newtasktype.remun_unit_rate" type="number" label="Task type rate (OPTIONAL)" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-btn color="success" @click="addDeliverable">Add deliverable</mdb-btn>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <div class="max-vh-50 mt-2">
              <deliverable-form v-for="(deliverable, index) in deliverables" :key="index" v-model="deliverables[index]"/>
            </div>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <div>
          <mdb-btn color="primary" v-on:click="saveNewTaskType">Save</mdb-btn>
          <mdb-btn outline="info" @click="addTaskTypeModal=false;">Close</mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="openDuplicateModal" @close="openDuplicateModal = false" info size="lg">
      <mdb-modal-header>
        <mdb-modal-title>Duplicate Task Type - {{ duplicatedTaskType.name }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row v-if='modalerror' class='justify-content-around pt-4'>
          <mdb-col md='12'>
            <mdb-alert color='danger' >{{modalerror}}</mdb-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="newNameDuplicateTaskType" label="Add a Name For Duplicated Task Type" />
            <mdb-input v-model="newCodeDuplicateTaskType" label="Add a Code For Duplicated Task Type" />
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <div>
          <mdb-btn color="primary" v-on:click="duplicateTaskType">Duplicate</mdb-btn>
          <mdb-btn outline="info" @click="openDuplicateModal=false;">Close</mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="!!skillselected || !!tasktypeselected" @close="skillselected = false; tasktypeselected = false" info size="lg">
      <mdb-modal-header>
        <mdb-modal-title v-if="skillselected">Skill edition</mdb-modal-title>
        <mdb-modal-title v-else>Task type edition</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body v-if="!!skillselected">
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="skillselected.code" label="Code" read-only />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="skillselected.name" label="Name" />
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-body v-if="!!tasktypeselected">
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="tasktypeselected.code" label="Code" read-only />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="tasktypeselected.name" label="Name" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <h3>Payable units (optional)</h3>
            <select v-model="tasktypeselected.remun_unit">
              <option value="">None</option>
              <option value="MIN">Minutes</option>
              <option value="DAY">Days</option>
            </select>
            <mdb-input v-model="tasktypeselected.remun_unit_nb" label="Number of units (OPTIONAL)" />
            <mdb-input v-model="tasktypeselected.remun_unit_rate" type="number" label="Task type rate (OPTIONAL)" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-btn color="success" @click="tasktypeselected.deliverables.push({type: 'file', displayname: '', name: '', public: false, maxDuration: '', maxSize: ''})">Add deliverable</mdb-btn>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <div class="max-vh-50 mt-2">
              <deliverable-form
                v-for="(deliverable, index) in tasktypeselected.deliverables"
                :key="index"
                v-model="tasktypeselected.deliverables[index]"
                :deletable="true"
                :index="index"
                :onDelete="deleteDeliverable"
              />
            </div>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <div class="modal-fluid">
          <mdb-btn color="danger" v-on:click="deleteitem()" class="float-left">Delete</mdb-btn>
          <mdb-btn color="success" v-if="tasktypeselected" @click="openModalToDuplicate">Duplicate</mdb-btn>
          <div class="float-right">
            <mdb-btn color="primary" v-on:click="editNewTaskType">Save</mdb-btn>
            <mdb-btn outline="info" @click="skillselected = false; tasktypeselected = false">Close</mdb-btn>
          </div>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbDatatable2,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import Apicall from '@/libs/Apicall';
import DeliverableForm from '@/components/DeliverableForm.vue';

export default {
  name: 'taskconfigs',
  components: {
    Header,
    Admintopbar,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbCol,
    mdbDatatable2,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    DeliverableForm,
  },
  data() {
    return {
      error: '',
      success: false,
      searchTasktype: '',
      searchTasktemplate: '',
      tasktemplates: {
        rows: [],
      },
      searchSkill: '',
      newskill: {},
      newtasktype: {},
      duplicatedTaskType: {},
      skillselected: false,
      tasktypeselected: false,
      modalerror: '',
      addSkillModal: false,
      openDuplicateModal: false,
      addTaskTypeModal: false,
      newNameDuplicateTaskType: '',
      newCodeDuplicateTaskType: '',
      deliverables: [],
      breadcrumb: [
        {
          title: 'Tasks Configs.',
          href: '/admin/tasks-configs',
        },
        {
          title: 'Overview',
          href: null,
        },
      ],
      skills: {
        columns: [
          {
            label: 'Code',
            field: 'code',
            sort: true,
          },
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
        ],
        rows: [],
      },
      tasktype: {
        columns: [
          {
            label: 'Code',
            field: 'code',
            sort: true,
          },
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
        ],
        rows: [],
      },
    };
  },
  mounted() {
    this.loadAllSkills();
    this.loadAllTaskTypes();
    this.loadAllTaskTemplates();
  },
  computed: {
    taskTemplateRows() {
      return {
        columns: [{
          label: 'ID',
          field: 'id',
          sort: false,
        },
        {
          label: 'Name',
          field: 'name',
          sort: true,
        }],
        rows: this.tasktemplates.rows.map((template) => ({
          id: template.id,
          name: template.name,
        })),
      };
    },
  },
  methods: {
    loadAllSkills() {
      const self = this;
      const api = new Apicall();
      self.skills.rows = [];
      api.call('GET', 'api/admintaskconfig/skills-list').then((response) => {
        if (response.status === 'OK') {
          self.skills.rows = response.data;
        } else {
          self.error = response.msg;
        }
      });
    },
    loadAllTaskTypes() {
      const self = this;
      const api = new Apicall();
      self.tasktype.rows = [];
      self.success = false;
      api.call('GET', 'api/admintaskconfig/tasktype-list').then((response) => {
        if (response.status === 'OK') {
          self.tasktype.rows = response.data;
        } else {
          self.error = response.msg;
        }
      });
    },
    loadAllTaskTemplates() {
      const api = new Apicall();
      this.tasktemplates.rows = [];
      api.call('GET', 'api/task/taskqueue-templates').then((response) => {
        if (response.status === 'OK') {
          this.tasktemplates.rows = response.data.templates;
        } else {
          this.error = response.msg;
        }
      });
    },
    openModalToDuplicate() {
      this.duplicatedTaskType = this.tasktypeselected;
      this.openDuplicateModal = true;
      this.tasktypeselected = false;
    },
    showAddSkill() {
      this.success = false;
      this.addSkillModal = true;
    },
    showAddTaskType() {
      this.success = false;
      this.addTaskTypeModal = true;
    },
    saveNewSkill() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/admintaskconfig/skill-add', this.newskill).then((response) => {
        if (response.status === 'OK') {
          self.skills.rows = [];
          self.loadAllSkills();
          self.success = true;
          self.addSkillModal = false;
          const rootEle = document.documentElement;
          rootEle.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          self.newskill = {};
        } else {
          self.modalerror = response.msg;
        }
      });
    },
    saveNewTaskType() {
      const self = this;

      if (this.newtasktype.code.endsWith('R')) {
        // eslint-disable-next-line no-restricted-globals, no-alert
        if (!confirm("Are you sure? Tasks whose code ends with 'R' will be considered as spotcheck. Do you want to continue?")) {
          return;
        }
      }

      const api = new Apicall();
      this.newtasktype.deliverables = this.deliverables;
      api.call('POST', 'api/admintaskconfig/tasktype-add', this.newtasktype).then((response) => {
        if (response.status === 'OK') {
          self.tasktype.rows = [];
          self.loadAllTaskTypes();
          self.success = true;
          self.addTaskTypeModal = false;
          const rootEle = document.documentElement;
          rootEle.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          self.newtasktype = {};
        } else {
          self.modalerror = response.msg;
        }
      });
    },
    editNewTaskType() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/admintaskconfig/tasktype-edit', this.tasktypeselected).then((response) => {
        if (response.status === 'OK') {
          self.tasktype.rows = [];
          self.loadAllTaskTypes();
          self.success = true;
          self.addTaskTypeModal = false;
          const rootEle = document.documentElement;
          rootEle.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          self.tasktypeselected = {};
        } else {
          self.modalerror = response.msg;
        }
      });
    },
    duplicateTaskType() {
      const self = this;
      const api = new Apicall();
      if (this.newNameDuplicateTaskType === '' || this.newCodeDuplicateTaskType === '') {
        this.modalerror = 'You must add a name and a code for the duplicated task type';
        setTimeout(() => {
          this.modalerror = '';
        }, 2000);
        return;
      }
      api.call('POST', 'api/admintaskconfig/duplicate-task-type', { newTaskTypeName: self.newNameDuplicateTaskType, taskTypeId: self.duplicatedTaskType.tasktypeid, newTaskTypeCode: self.newCodeDuplicateTaskType }).then((response) => {
        if (response.status === 'OK') {
          self.tasktype.rows = [];
          self.loadAllTaskTypes();
          self.openDuplicateModal = false;
          self.success = true;
          self.newNameDuplicateTaskType = '';
          self.newCodeDuplicateTaskType = '';
        } else {
          self.modalerror = response.msg;
        }
      });
    },
    showSkillOptions(event) {
      this.skillselected = { code: event.code, name: event.name };
    },
    showTasktypeOptions(event) {
      this.tasktypeselected = {
        tasktypeid: event.tasktypeid,
        code: event.code,
        name: event.name,
        remun_unit: event.remun_unit,
        remun_unit_nb: event.remun_unit_nb,
        remun_unit_rate: event.remun_unit_rate,
        deliverables: event.deliverables ?? [],
      };
    },
    addDeliverable() {
      this.deliverables.push({
        type: 'file',
        displayname: '',
        name: '',
        public: false,
        maxDuration: '',
        maxSize: '',
      });
    },
    edititem() {
      const self = this;
      const api = new Apicall();
      const data = {
        type: this.skillselected !== false ? 'skill' : 'tasktype',
        code: this.skillselected !== false ? this.skillselected.code : this.tasktypeselected.code,
        name: this.skillselected !== false ? this.skillselected.name : this.tasktypeselected.name,
      };
      api.call('POST', 'api/admintaskconfig/edit-item', data).then(() => {
        self.skillselected = false;
        self.tasktypeselected = false;
        if (data.type === 'skill') {
          self.loadAllSkills();
        } else {
          self.loadAllTaskTypes();
        }
      });
    },
    deleteitem() {
      const self = this;
      const api = new Apicall();
      const data = {
        type: this.skillselected !== false ? 'skill' : 'tasktype',
        code: this.skillselected !== false ? this.skillselected.code : this.tasktypeselected.code,
      };
      api.call('POST', 'api/admintaskconfig/delete-item', data).then(() => {
        self.skillselected = false;
        self.tasktypeselected = false;
        if (data.type === 'skill') {
          self.loadAllSkills();
        } else {
          self.loadAllTaskTypes();
        }
      });
    },
    onInputDeliverable(index, deliverable) {
      if (this.deliverables[index]) {
        this.deliverables[index].type = deliverable.type;
        this.deliverables[index].displayname = deliverable.displayname;
        this.deliverables[index].name = deliverable.name;
        this.deliverables[index].public = deliverable.public;
        this.deliverables[index].maxDuration = deliverable.maxDuration;
        this.deliverables[index].maxSize = deliverable.maxSize;
      }
    },
    deleteDeliverable(index) {
      console.log(index);
      if (this.tasktypeselected) {
        this.tasktypeselected.deliverables.splice(index, 1);
      }
    },
  },
  watch: {
    addTaskTypeModal(newValue) {
      if (!newValue) {
        this.deliverables = [];
      }
    },
  },
};
</script>
<style>
  .tablewithcursor tr {
    cursor: pointer;
  }
</style>
