<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar :breadcrumb='breadcrumb' />
    <mdb-row class="pt-4 justify-content-around">
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="grey accent-3">Details</mdb-card-header>
          <mdb-card-body>
            <mdb-row v-if='error' class='justify-content-around pt-4'>
              <mdb-col md='8'>
                <mdb-alert color='danger' >{{error}}</mdb-alert>
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="First Name" v-model="customer.first_name" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Last Name" v-model="customer.last_name" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Email" v-model="customer.email" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Company" v-model="customer.company" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Cell" v-model="customer.cell" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Stripe" v-model="customer.stripe_id" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Language" v-model="customer.language" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Youtube" v-model="customer.youtube_url" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Facebook" v-model="customer.facebook_url" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Twitter" v-model="customer.twitter_url" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Instagram" v-model="customer.instagram_url" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Linkedin" v-model="customer.linkedin_url" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Currency" placeholder="USD" v-model="customer.currency" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Ref identifier" v-model="customer.ref_ident" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Referenced by" v-model="customer.ref_by" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input type="textarea" label="Notes" :rows="2" v-model="customer.notes" outline />
              </mdb-col>
            </mdb-row>
            <mdb-btn color="success" v-on:click="saveCustomerInfos">Save</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbBtn,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'Home',
  components: {
    Header,
    Admintopbar,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbBtn,
  },
  data() {
    return {
      error: '',
      customer: {},
      breadcrumb: [
        {
          title: 'Customers',
          href: '/admin/customers',
        },
        {
          title: 'Add',
          href: null,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    saveCustomerInfos() {
      const self = this;
      const api = new Apicall();
      self.error = '';
      api.call('POST', 'api/admincustomer/new', this.customer).then((response) => {
        if (response.status === 'OK') {
          this.$router.push({ name: 'AdminCustomerInfos', params: { customerid: response.data.customerid } });
        } else {
          self.error = response.msg;
          const rootEle = document.documentElement;
          rootEle.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      });
    },
  },
};
</script>
