<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if='error' class='justify-content-around pt-4'>
      <mdb-col md='8'>
        <mdb-alert color='danger' >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <Admintopbar :breadcrumb='breadcrumb' />
    <mdb-row class="pt-4">
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="grey accent-3">Listing</mdb-card-header>
          <mdb-card-body>
            <mdb-input class="mt-0" v-model="search" label="Search" />
            <mdb-datatable-2 v-model='customers' @selected='chooseCustomer($event)' selectable :searching="search" v-if="customers.rows.length" />
            <mdb-btn outline="success" v-on:click="addNewCustomer" class="float-right">New customer</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="mdb-color darken-1">Details</mdb-card-header>
          <mdb-card-body>
            <div v-if="!currentcustomer">
              <h4>Please choose a customer</h4>
            </div>
            <div v-else>
              <h5>General infos</h5>
              <mdb-input :disabled="true" label="First name" v-model="currentcustomer.first_name" size="sm" />
              <mdb-input :disabled="true" label="Last name" v-model="currentcustomer.last_name" size="sm" />
              <mdb-input :disabled="true" label="email" v-model="currentcustomer.email" size="sm" />
              <mdb-input :disabled="true" label="Company" v-model="currentcustomer.company" size="sm" />
              <mdb-input :disabled="true" label="Cell phone" v-model="currentcustomer.cell" size="sm" />
              <mdb-input :disabled="true" label="Effort balance" v-model="currentcustomer.effort_balance" size="sm" />
              <mdb-input :disabled="true" label="Renew subscription date" :value="'current_period_end' in currentcustomer.subscription ? timestampToString(currentcustomer.subscription.current_period_end) : ''" size="sm" />
              <h5 v-if="currentcustomer.projects.length">Last projects ({{currentcustomer.projects.length}})</h5>
              <h5 v-else>This customer has no active project</h5>
              <mdb-tbl v-if="currentcustomer.projects.length" sm>
                <mdb-tbl-head>
                  <tr>
                    <th>Name</th>
                    <th>Frequence</th>
                    <th>&nbsp;</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr v-for="(project, index) in currentcustomer.projects" v-bind:key="index">
                    <td>{{project.name}}</td>
                    <td>{{project.frequence}}</td>
                    <td><mdb-btn icon="eye" size="sm" outline="elegant" v-on:click="viewCustomerProject(currentcustomer, project)" /></td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
              <hr />
              <mdb-btn class="float-right" v-on:click="viewcustomerMoreInfos(currentcustomer)">View more informations</mdb-btn>
            </div>
          </mdb-card-body>
        </mdb-card>
        <br />
        <mdb-card v-if="currentcustomer">
          <mdb-card-header color="mdb-color darken-1">
            Ledger
          </mdb-card-header>
          <mdb-card-body>
            <mdb-row>
              <mdb-col>
                <ledger-table :showSearchBar=false :customerId="currentcustomer.id"></ledger-table>
              </mdb-col>
            </mdb-row>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbDatatable2,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import LedgerTable from '@/components/LedgerTable.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'Home',
  components: {
    Header,
    Admintopbar,
    LedgerTable,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbCol,
    mdbDatatable2,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
  },
  data() {
    return {
      error: '',
      dummy: '',
      search: '',
      currentcustomer: null,
      breadcrumb: [
        {
          title: 'Customers',
          href: '/admin/customers',
        },
        {
          title: 'List',
          href: null,
        },
      ],
      customers: {
        columns: [
          {
            label: 'Full name',
            field: 'fullname',
            sort: true,
          },
          {
            label: 'Email',
            field: 'email',
            sort: true,
          },
          {
            label: 'Company',
            field: 'company',
            sort: true,
          },
        ],
        rows: [],
      },
    };
  },
  mounted() {
    this.chosenCustomer = this.$route.params.customer;
    this.selectedCustomer(this.chosenCustomer);
    this.loadAllCustomers();
  },
  computed: {
  },
  methods: {
    loadAllCustomers() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/admincustomer/list').then((response) => {
        if (response.status === 'OK') {
          self.customers.rows = response.data;
        } else {
          self.error = response.msg;
        }
      });
    },
    nl2br(str) {
      if (str) {
        return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
      }
      return '';
    },
    chooseCustomer(ev) {
      this.currentcustomer = null;
      if (ev) {
        const api = new Apicall();
        const self = this;
        this.currentcustomer = null;
        api.call('POST', 'api/admincustomer/get-one', { customerid: ev.id }).then((response) => {
          if (response.status === 'OK') {
            this.currentcustomer = response.data;
            const rootEle = document.documentElement;
            rootEle.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            self.error = response.msg;
          }
        });
      }
    },
    selectedCustomer(id) {
      this.currentcustomer = null;
      if (this.chosenCustomer) {
        const api = new Apicall();
        const self = this;
        this.currentcustomer = null;
        api.call('POST', 'api/admincustomer/get-one', { customerid: id }).then((response) => {
          if (response.status === 'OK') {
            this.currentcustomer = response.data;
            const rootEle = document.documentElement;
            rootEle.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            self.error = response.msg;
          }
        });
      }
    },
    addNewCustomer() {
      this.$router.push({ name: 'AdminCustomerAdd' });
    },
    viewCustomerProject(customer, project) {
      this.$router.push({ name: 'AdminCustomerProject', params: { customerid: customer.id, projectid: project.id } });
    },
    viewcustomerMoreInfos(customer) {
      this.$router.push({ name: 'AdminCustomerInfos', params: { customerid: customer.id } });
    },
    timestampToString(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toString();
    },
  },
};
</script>
