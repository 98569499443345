<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar />
    <mdb-row>
      <mdb-col>
        <mdb-card class="mt-4">
          <mdb-card-header color="grey accent-3">Taskqueue Template Form</mdb-card-header>
          <mdb-card-body>
            <taskqueue-template-form :taskqueueTemplateId="templateId" @taskqueuetemplate-save="$router.replace('/admin/tasks-configs')"/>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbCol,
  mdbContainer,
  mdbRow,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import TaskqueueTemplateForm from '@/components/TaskqueueTemplateForm.vue';

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbCol,
    mdbContainer,
    mdbRow,
    Header,
    Admintopbar,
    TaskqueueTemplateForm,
  },

  data() {
    return {
      templateId: null,
      loading: false,
    };
  },

  beforeMount() {
    if (this.$route.query.templateId) {
      this.templateId = this.$route.query.templateId;
    }
  },
};
</script>

<style>
</style>
