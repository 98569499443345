<template>
    <mdb-container fluid>
    <Header></Header>
    <Admintopbar/>
    <section class="mt-3">
      <mdb-row>
        <mdb-col md="6">
          <mdb-btn color="success" @click="goToTaskQueues()">Go to task queues</mdb-btn>
          <mdb-btn color="success" @click="goToAddTaskQueue()">Add a task queue</mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="blue accent-3">
              Project # {{ projectId }}
              <span style="font-size: 1.5rem;"><mdb-badge v-if="project.disabled_at" color="danger">Disabled</mdb-badge></span>
            </mdb-card-header>
            <mdb-card-body>
                <mdb-tbl responsive sm>
                  <mdb-tbl-body class="main">
                    <tr><td style="font-weight: bold;">Project Id</td><td>{{ projectId }}</td></tr>
                    <tr><td style="font-weight: bold;">Added at</td><td>{{ project.added_at }}</td></tr>
                    <tr>
                      <td style="font-weight: bold;">Project name</td>
                      <td>
                        <input v-model="editableProjectName" type="text" class="form-control" placeholder="Edit project name">
                      </td>
                    </tr>
                    <tr><td style="font-weight: bold;">Customer name</td><td><a @click="goToCustomerDetails(customer.id)">{{ customer.first_name }} {{ customer.last_name }} <mdb-icon icon="external-link-alt"/></a></td></tr>
                    <tr><td style="font-weight: bold;">Customer email</td><td><a @click="copyToClipboard(customer.email)">{{ customer.email }} <mdb-icon icon="clone"/></a></td></tr>
                    <tr v-if="project.disabled_at"><td style="font-weight: bold;">Disabled at</td><td>{{ project.disabled_at }}</td></tr>
                  </mdb-tbl-body>
                  <mdb-btn color="success" @click="updateProjectName()" v-if="editableProjectName !== project.name">Save Changes</mdb-btn>
                </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="purple accent-3">Task queues</mdb-card-header>
            <mdb-card-body>
              <mdb-btn color="success" @click="duplicateTaskQueue()" class="float-right" :disabled="!selectedTaskQueueID">Duplicate Task Queue</mdb-btn>
              <div v-if="Object.keys(selectedItems).length > 0">
                <mdb-btn v-on:click="showChangeProjectModal = true" size="sm" color="success">Modify the assigned project for all task queues selected</mdb-btn>
              </div>
              <div v-if="taskQueues.length>0">
                <mdb-tbl responsive sm>
                  <mdb-tbl-head>
                    <tr>
                      <th style="font-weight: bold;">Select</th>
                      <th style="font-weight: bold;">ID</th>
                      <th style="font-weight: bold;">Added at</th>
                      <th style="font-weight: bold;">Task Queue Name</th>
                      <th style="font-weight: bold;">Billing unit quantity</th>
                      <th style="font-weight: bold;"></th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body class="main" style="cursor: pointer;">
                    <tr v-for="(taskqueue,index) in taskQueues" v-bind:key="index" @click="setSelectedTaskQueue(taskqueue)" :class="(selectedTaskQueueID===taskqueue.taskqueueid ? 'selected-task-queue' : newTaskQueue===taskqueue.taskqueueid ? 'new-added-task-queue' : '') + (taskqueue.deleted_at !== null ? ' bg-danger' : '')">
                      <td style="display: flex; justify-content: center;" @click="checkboxClickHandle">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" :id="taskqueue.taskqueueid" v-model="selectedItems[taskqueue.taskqueueid]" v-on:change="handleCheckboxChange" class="custom-control-input">
                          <label class="custom-control-label" :for="taskqueue.taskqueueid"></label>
                        </div>
                      </td>
                      <td>{{taskqueue.taskqueueid}}</td>
                      <td>
                        {{taskqueue.addedat}}
                      </td>
                      <td>{{taskqueue.queuename}}</td>
                      <td>
                        {{taskqueue.billingunitquantity}}
                      </td>
                      <td>
                        <mdb-btn
                          icon="eye" size="sm" outline="elegant"
                          @click="$router.push({ name: 'EditTaskQueue', params: {taskqueueid: taskqueue.taskqueueid } })"
                        >
                        </mdb-btn>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <mdb-modal :show="showChangeProjectModal" @close="showChangeProjectModal = false" info>
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">Select a project</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3">
        <mdb-alert v-if="projectModalError" color="danger">{{projectModalError}}</mdb-alert>
        <select
          class="browser-default custom-select"
          id="projectNames"
          @change="handleProjectChange"
          >
          <option
            v-for="(
              projectItem, index
            ) in projectList"
            :key="index"
            :value="projectItem.projectid"
            :selected="projectItem.projectid == projectId"
          >
            {{ projectItem.projectid }} - {{ projectItem.name }} - {{ projectItem.client.firstname || projectItem.client.lastname ? projectItem.client.firstname + ' ' + projectItem.client.lastname : projectItem.client.email }}

          </option>
        </select>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn v-on:click="saveNewProjectForTaskqueues" outline="success">Save</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbBadge,
  mdbCard,
  mdbCol,
  mdbContainer,
  mdbTbl,
  mdbTblBody,
  mdbBtn,
  mdbCardHeader,
  mdbCardBody,
  mdbIcon,
  mdbTblHead,
  mdbRow,
  mdbAlert,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbModalTitle,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';

export default {
  components: {
    mdbBadge,
    mdbCard,
    mdbBtn,
    mdbCol,
    mdbContainer,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbIcon,
    mdbAlert,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbModalTitle,
    Header,
    Admintopbar,
  },
  data() {
    return {
      error: null,
      taskqueue: null,
      project: null,
      customer: null,
      taskQueues: [],
      selectedItems: {},
      projectId: null,
      customerId: null,
      selectedProjectIdForTaskqueues: null,
      selectedTaskQueueID: null,
      showChangeProjectModal: false,
      projectList: [],
      projectModalError: null,
      timer: null,
      newTaskQueue: null,
      editableProjectName: '',
    };
  },
  mounted() {
    this.projectId = this.$route.params.projectid;
    this.customerId = this.$route.params.customerid;
    this.getProjectInfos(this.projectId);
    this.getCustomerInfos(this.customerId);
    this.getAllTaskQueues(this.projectId);
    this.getAllProjects();
  },

  methods: {
    getProjectInfos(projectId) {
      const apiCall = new Apicall();

      apiCall
        .call('POST',
        'api/project/get-one-project',
        { projectid: projectId }).then((response) => {
          if (response.status === 'OK') {
            this.project = response.data.projectinfos;
            this.editableProjectName = this.project.name;
          } else {
            this.error = response.msg;
          }
        });
    },
    updateProjectName() {
      const apiCall = new Apicall();
      apiCall.call('POST', 'api/project/update-name', {
        projectid: this.projectId,
        name: this.editableProjectName,
      }).then((response) => {
        if (response.status === 'OK') {
          this.notificationSusscess('Project name updated successfully');
          this.project.name = response.data.name;
        } else {
          this.notificationError('Failed to update project name');
        }
      });
    },
    getAllProjects() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/project/get-all?include-disabled=false', null).then((response) => {
        if (response.status === 'OK') {
          this.projectList = response.data.projectinfos;
        } else {
          this.$emit('error', response.msg);
        }
      });
    },
    handleProjectChange(event) {
      this.selectedProjectIdForTaskqueues = event.target.value;
    },
    saveNewProjectForTaskqueues() {
      const apiCall = new Apicall();
      const selectedItems = this.validationForSelectedItemsProcess();
      if (!selectedItems) {
        this.projectModalError = 'You didn\'t select any item, please select at least 1 item';
        setTimeout(() => {
          this.projectModalError = null;
        }, 2500);
        return;
      }
      if (this.selectedProjectIdForTaskqueues === this.projectId || !this.selectedProjectIdForTaskqueues) {
        this.projectModalError = 'You have to select a different project';
        setTimeout(() => {
          this.projectModalError = null;
        }, 2500);
        return;
      }
      apiCall
        .call(
          'POST',
          'api/task/assign-project-to-taskqueues',
          { projectId: this.selectedProjectIdForTaskqueues, selectedItems },
          ).then((response) => {
            if (response.status === 'OK') {
              this.showChangeProjectModal = false;
              const newProject = response.data.project;
              this.goToCustomerProject(newProject.user_id, newProject.id);
            } else {
              this.error = response.msg;
              this.showChangeProjectModal = false;
            }
      });
    },
    validationForSelectedItemsProcess() {
      if (!Object.keys(this.selectedItems).length > 0) {
        return null;
      }
      const selItems = Object.keys(this.selectedItems);
      return selItems;
    },
    getCustomerInfos(customerId) {
      const apiCall = new Apicall();

      apiCall
        .call('POST',
        'api/admincustomer/get-one',
        { customerid: customerId }).then((response) => {
          if (response.status === 'OK') {
            this.customer = response.data;
          } else {
            this.error = response.msg;
          }
        });
    },
      checkboxClickHandle(event) {
        if (event) {
          event.stopPropagation(); // Stop it from automatically triggering the row selection
        }
      },
    getAllTaskQueues(projectId) {
      this.loading = true;

      const apiCall = new Apicall();

      apiCall
        .call(
          'POST',
          'api/task/all-task-queues-project?include-deleted=1',
          { projectid: projectId },
          ).then((response) => {
          this.loading = false;

          if (response.status === 'OK') {
            this.taskQueues = response.data.taskqueueinfos;
          } else {
            this.error = response.msg;
          }
        });
    },
    handleCheckboxChange(event) {
      const itemId = event.target.id;
      this.selectedItems[itemId] = event.target.checked;
      if (event.target.checked) {
        this.selectedItems[itemId] = true;
      } else {
        // Remove item from selectedItems if unchecked
        delete this.selectedItems[itemId];
      }
    },
    goToCustomerDetails(id) {
      this.$router.push({ name: 'AdminCustomers', params: { customer: id } });
    },
    goToTaskQueues() {
      this.$router.push({ name: 'Task' });
    },
    goToCustomerProject(customerId, projectId) {
      this.$router.push({ name: 'AdminCustomerProject', params: { customerid: customerId, projectid: projectId } });
    },
    goToAddTaskQueue() {
      this.$router.push({ name: 'NewTaskQueue' });
    },
    copyToClipboard(copiedText) {
      const input = document.createElement('textarea');
      input.innerHTML = copiedText;
      document.body.appendChild(input);
      input.select();
      const result = document.execCommand('copy');
      document.body.removeChild(input);
    },
    setSelectedTaskQueue(task) {
      this.selectedTaskQueueID = task.taskqueueid;
    },
    notificationSusscess(_messsage) {
        this.$notify.success({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
    notificationError(_messsage) {
        this.$notify.error({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
    duplicateTaskQueue() {
      this.newTaskQueue = null;
      const apiCall = new Apicall();
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        apiCall.call(
          'POST',
          'api/task/duplicate-task-queue',
          { taskqueueid: this.selectedTaskQueueID },
        ).then((response) => {
          if (response.status === 'OK') {
              this.getAllTaskQueues(this.projectId);
              this.newTaskQueue = response.data.taskqueueinfos.taskqueueid;
              this.notificationSusscess(`Task queue duplicated. New task queue ID: ${response.data.taskqueueinfos.taskqueueid}`);
          } else {
              this.notificationError(response.msg);
          }
        });
      }, 1000);
    },
  },
};
</script>
<style scoped>
  .selected-task-queue{
    background-color:#68ffca;
  }
  .new-added-task-queue{
    background-color:#d491ee;
  }
</style>
