<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if='error' class='justify-content-around pt-4'>
      <mdb-col md='8'>
        <mdb-alert color='danger' >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <mdb-row v-if='genericsuccess' class='justify-content-around pt-4'>
      <mdb-col md='8'>
        <mdb-alert color='success' >{{genericsuccess}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <Admintopbar :breadcrumb='breadcrumb' />
    <mdb-row class="pt-4">
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="grey accent-3">Details</mdb-card-header>
          <mdb-card-body v-if="customer">
            <mdb-alert color='success' v-if="success" >{{success}}</mdb-alert>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="First Name" v-model="customer.first_name" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Last Name" v-model="customer.last_name" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Email" v-model="customer.email" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Company" v-model="customer.company" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Apikey" v-model="customer.apikey" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Cell" v-model="customer.cell" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Stripe" v-model="customer.stripe_id" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Language" v-model="customer.language" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Youtube" v-model="customer.youtube_url" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Facebook" v-model="customer.facebook_url" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Twitter" v-model="customer.twitter_url" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Instagram" v-model="customer.instagram_url" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Linkedin" v-model="customer.linkedin_url" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Currency" v-model="customer.currency" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input label="Ref identifier" v-model="customer.ref_ident" size="sm" />
              </mdb-col>
              <mdb-col>
                <mdb-input label="Referenced by" v-model="customer.ref_by" size="sm" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="py-0">
              <mdb-col>
                <mdb-input type="textarea" label="Notes" :rows="2" v-model="customer.notes" outline />
              </mdb-col>
            </mdb-row>
            <mdb-btn color="success" v-on:click="saveCustomerInfos">Save</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md='6'>
        <mdb-card>
          <mdb-card-header color="mdb-color darken-1">Projects</mdb-card-header>
          <mdb-card-body v-if="customer">
            <h5 v-if="!customer.projects.length">This customer has no active project</h5>
            <mdb-tbl v-if="customer.projects.length" sm>
              <mdb-tbl-head>
                <tr>
                  <th>Name</th>
                  <th>Frequence</th>
                  <th>&nbsp;</th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr v-for="(project, index) in customer.projects" v-bind:key="index">
                  <td>{{project.name}}</td>
                  <td>{{project.frequence}}</td>
                  <td><mdb-btn icon="eye" size="sm" outline="elegant" v-on:click="viewCustomerProject(project)" /></td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <hr />
            <mdb-btn class="float-right" v-on:click="newProject">New project</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
    <mdb-modal :show="shownewprojectmodal" @close="shownewprojectmodal = false" info>
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">New project</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3">
        <mdb-alert v-if="newprojecterror" color="danger">{{newprojecterror}}</mdb-alert>
        <mdb-input label="Frequence" v-model="newproject.frequence"/>
        <mdb-input label="Name" v-model="newproject.name"/>
      </mdb-modal-body>
      <mdb-modal-footer center v-if="newproject.frequence && newproject.name">
        <mdb-btn v-on:click="saveNewProject" outline="success">Save</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbModalTitle,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'Home',
  components: {
    Header,
    Admintopbar,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbModalTitle,
  },
  data() {
    return {
      error: '',
      success: '',
      genericsuccess: '',
      customer: null,
      newproject: {
        frequence: '',
        name: '',
      },
      shownewprojectmodal: false,
      newprojecterror: '',
      breadcrumb: [
        {
          title: 'Customers',
          href: '/admin/customers',
        },
        {
          title: '',
          href: null,
        },
      ],
    };
  },
  mounted() {
    this.loadCustomerInfos();
  },
  computed: {
  },
  methods: {
    loadCustomerInfos() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/admincustomer/get-one', { customerid: this.$route.params.customerid, nolimit: true }).then((response) => {
        if (response.status === 'OK') {
          self.customer = response.data;
          self.breadcrumb[1].title = `${response.data.first_name} ${response.data.last_name}`;
        } else {
          self.error = response.msg;
        }
      });
    },
    saveCustomerInfos() {
      const self = this;
      const api = new Apicall();
      self.success = '';
      self.error = '';
      api.call('POST', 'api/admincustomer/update-infos', this.customer).then((response) => {
        if (response.status === 'OK') {
          self.success = 'Customer successfully updated!';
          self.loadCustomerInfos();
        } else {
          self.error = response.msg;
        }
        const rootEle = document.documentElement;
        rootEle.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    },
    viewCustomerProject(project) {
      this.$router.push({ name: 'AdminCustomerProject', params: { customerid: this.customer.id, projectid: project.id } });
    },
    newProject() {
      this.newproject.code = '';
      this.newproject.name = '';
      this.shownewprojectmodal = true;
      this.genericsuccess = '';
    },
    saveNewProject() {
      const self = this;
      const api = new Apicall();
      this.success = '';
      this.newprojecterror = '';
      api.call('POST', 'api/adminproject/add', { customerid: this.customer.id, project: this.newproject }).then((response) => {
        if (response.status === 'OK') {
          self.genericsuccess = 'New project created successfully';
          this.shownewprojectmodal = false;
          const rootEle = document.documentElement;
          rootEle.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          self.loadCustomerInfos();
        } else {
          self.newprojecterror = response.msg;
        }
      });
    },
  },
};
</script>
