<template>
    <form @submit="onSubmit" @input="onInput">
      <mdb-container>
        <mdb-row>
          <mdb-col>
            <select v-model="type" label="File" required class="browser-default custom-select">
              <option value="file">File</option>
              <option value="video-file">Video file</option>
              <option value="image-file">Image file</option>
              <option value="csv-file">CSV File</option>
              <option value="zip">Zip file</option>
            </select>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input type="text" label="Display Name" v-model="displayname" required/>
          </mdb-col>
          <mdb-col>
            <mdb-input type="text" label="Name" v-model="name" required/>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col class="col-6">
            Public Delivery
            <mdb-switch v-model="public" offLabel="No" onLabel="Yes" />
          </mdb-col>
          <mdb-col class="col-6">
            Optional
            <mdb-switch v-model="optional" offLabel="No" onLabel="Yes" />
          </mdb-col>
          <mdb-col v-if="type === 'video-file'" class="col-6">
            Transcribe this file
            <mdb-switch v-model="isVideoToTranscribe" offLabel="No" onLabel="Yes" />
          </mdb-col>
          <mdb-col v-if="type === 'video-file'" class="col-6">
            <mdb-input type="text" label="Max Duration (SECONDS)" v-model="maxDuration" />
          </mdb-col>
          <mdb-col class="col-6">
            <mdb-input type="number" label="Max size in gigabytes (OPTIONAL)" v-model="maxSize" />
          </mdb-col>
        </mdb-row>
        <mdb-row v-if="deletable" class="mb-3">
          <mdb-col>
            <mdb-btn color="danger" @click="deleteDeliverable" class="delete-btn">Delete this deliverable</mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      </form>
</template>

<script>
import {
 mdbCol, mdbContainer, mdbInput, mdbRow, mdbSwitch, mdbBtn,
} from 'mdbvue';

export default {
components: {
    mdbCol,
    mdbContainer,
    mdbInput,
    mdbRow,
    mdbSwitch,
    mdbBtn,
},
data() {
  return {
    type: 'file',
    displayname: '',
    name: '',
    public: false,
    optional: false,
    isVideoToTranscribe: false,
    maxDuration: '',
    maxSize: '',
  };
},
emits: ['submit', 'input'],
methods: {
  onInput() {
    this.$emit('input', this.$data);
  },
  onSubmit(event) {
    event.preventDefault();
    this.$emit('submit', this.$data);
  },
  deleteDeliverable() {
    this.onDelete(this.index);
  },
},
mounted() {
  if (this.value) {
    this.type = this.value.type;
    this.optional = this.value.optional;
    this.displayname = this.value.displayname;
    this.name = this.value.name;
    this.public = this.value.public;
    this.isVideoToTranscribe = this.value.isVideoToTranscribe;
    this.maxDuration = this.value.maxDuration;
    this.maxSize = this.value.maxSize;
  }
},
props: {
  value: {
    type: Object,
    require: false,
  },
  index: {
    type: Number,
    required: false,
  },
  onDelete: {
    type: Function,
    required: false,
  },
  deletable: {
    type: Boolean,
    required: false,
    default: false,
  },
},
watch: {
  value(newValue) {
    if (newValue) {
      this.type = newValue.type;
      this.displayname = newValue.displayname;
      this.name = newValue.name;
      this.public = newValue.public;
      this.isVideoToTranscribe = newValue.isVideoToTranscribe;
      this.maxDuration = newValue.maxDuration;
      this.optional = newValue.optional;
    } else {
      this.type = 'file';
      this.displayname = '';
      this.name = '';
      this.public = false;
      this.optional = false;
      this.isVideoToTranscribe = false;
      this.maxDuration = '';
      this.maxSize = '';
    }
  },
},
};
</script>

<style>

</style>
