<template>
  <div>
    <mdb-input v-model="task.name" label="Task Name" />
    <label for="type">Type</label>
    <select v-model="task.type" required class="browser-default custom-select">
      <option v-for="(type, i) in taskTypes" :key="i" :value="type.code">{{type.code}}</option>
    </select>
    <mdb-select v-model="taskSkills" label="Skills" :multiple="true" :taggable="true" :visibleOptions="10" @change="changeSkills" />
    <mdb-input v-model="task.remun_unit_nb" label="Remuneration Unit Number" type="number" :min="0" :step=".01"/>
    <select v-model="task.remun_unit" label="Remuneration Unit" required class="browser-default custom-select">
      <option value="SEC">Seconds</option>
      <option value="MIN">Minutes</option>
      <option value="HOU">Hours</option>
      <option value="DAY">Days</option>
      <option value="WEE">Weeks</option>
    </select>
    <mdb-input v-model="task.remun_unit_rate" label="Remuneration Rate" type="number" :min="0.0" :step=".01"/>
    <mdb-input outline type="textarea" :rows="5" v-model="task.instructions" />
  </div>
</template>

<script>
import {
  mdbInput,
  mdbSelect,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
    mdbInput,
    mdbSelect,
  },
  data() {
    return {
      taskTypes: [],
      skillList: {},
      taskSkills: [],
    };
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getTaskTypes();
    this.getSkills();
  },
  methods: {
    getTaskTypes() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/task/get-types', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.taskTypes = response.data.tasktypes;
          }
        });
    },
    getSkills() {
      const apiCall = new Apicall();
      apiCall.call('GET', 'api/task/get-skills', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.skillList = response.data.taskSkills;
            this.reloadSkillsOfSelectedTask();
          }
        });
    },
    reloadSkillsOfSelectedTask() {
      this.taskSkills = Object.keys(this.skillList).map((key) => {
        const element = this.skillList[key];
        const found = this.task.skills.find((skill) => skill.toUpperCase() === element.code.toUpperCase());
        let result = null;
        if (found) {
          result = { value: element.code, text: element.code, selected: true };
        } else {
          result = { value: element.code, text: element.code, selected: false };
        }
        return result;
      });

      this.task.skills = this.taskSkills.map((skillObject) => skillObject.code);
    },
    changeSkills() {
      this.task.skills = this.taskSkills.filter((skillObject) => skillObject.selected)
        .map((skillObject) => skillObject.value);
    },
  },
};
</script>

<style>

</style>
