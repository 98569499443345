<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if="error" class="justify-content-around pt-1">
      <mdb-col md="6">
        <mdb-alert color="danger" >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <Admintopbar :breadcrumb="breadcrumb" />
    <section class="mt-3">
      <mdb-row>
        <mdb-col md="12" class="mt-5 mb-5">
          <mdb-card>
            <mdb-card-header color="blue accent-3">Taskqueues to follow</mdb-card-header>
            <mdb-card-body>
              <mdb-row>
                <mdb-col>
                  <mdb-tbl hover>
                    <mdb-tbl-head>
                      <tr>
                        <th>ID</th>
                        <th>Task Queue Name</th>
                        <th>Rush</th>
                        <th>Delay</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body v-if="taskqueuesToFollow" style="cursor: pointer;">
                      <tr v-for="(taskqueue, index) in taskqueuesToFollow" :key="index" @click="$router.push({ name: 'EditTaskQueue', params: {taskqueueid: taskqueue.taskqueueid}})">
                        <td>{{ taskqueue.taskqueueid }}</td>
                        <td>
                          {{ taskqueue.queuename }}
                        </td>
                        <td>{{ taskqueue.isrush }}</td>
                        <td>
                          {{ taskqueue.delay }}h ago
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="mdb-color darken-1">Tasks ready to be dispatched</mdb-card-header>
            <mdb-card-body>
              <mdb-row>
                <mdb-col>
                  <mdb-tbl hover>
                    <mdb-tbl-head>
                      <tr>
                        <th>ID</th>
                        <th>Task Queue Name</th>
                        <th>Tasks ready /<br /> Total tasks</th>
                        <th>Rush</th>
                        <th>Delay</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-spinner style="margin-top: 1.5rem;" small color="blue" v-if="isLoading===true" />
                    <mdb-tbl-body v-if="taskqueues && isLoading===false" style="cursor: pointer;">
                      <tr v-for="(taskqueue, index) in taskqueues" :key="index"
                        @click="$router.push({ name: 'EditTaskQueue', params: {taskqueueid: taskqueue.taskqueueid } })">
                          <td v-if="taskqueue.taskstodispatch > 0">{{taskqueue.taskqueueid}}</td>
                          <td v-if="taskqueue.taskstodispatch > 0">{{taskqueue.queuename}}<br />Project: {{taskqueue.project.name}}</td>
                          <td v-if="taskqueue.taskstodispatch > 0">{{taskqueue.taskstodispatch}} / {{taskqueue.totaltasks}} </td>
                          <td v-if="taskqueue.taskstodispatch > 0">{{taskqueue.isrush}}</td>
                          <td v-if="taskqueue.taskstodispatch > 0">{{taskqueue.delay}}h ago</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="blue accent-3">Tasks Assigned</mdb-card-header>
            <mdb-card-body>
              <mdb-row>
                <mdb-col>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <mdb-tbl hover>
                    <mdb-tbl-head>
                      <tr>
                        <th>ID</th>
                        <th>Task Queue Name</th>
                        <th>Tasks available /<br /> Total tasks</th>
                        <th>Tasks completed / <br />Total tasks</th>
                        <th>Rush</th>
                        <th>Delay</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-spinner style="margin-top: 1.5rem;" small color="blue" v-if="isLoading===true" />
                    <mdb-tbl-body v-if="taskqueues && isLoading===false" style="cursor: pointer;">
                      <tr v-for="(taskqueue, index) in taskqueues" :key="index"
                        @click="$router.push({ name: 'EditTaskQueue', params: {taskqueueid: taskqueue.taskqueueid } })">
                          <td v-if="taskqueue.tasksdone > 0 || taskqueue.taskstaken > 0">{{taskqueue.taskqueueid}}</td>
                          <td v-if="taskqueue.tasksdone > 0 || taskqueue.taskstaken > 0">{{taskqueue.queuename}}<br />Project: {{taskqueue.project.name}}</td>
                          <td v-if="taskqueue.tasksdone > 0 || taskqueue.taskstaken > 0">{{taskqueue.taskstaken}} / {{taskqueue.totaltasks}} </td>
                          <td v-if="taskqueue.tasksdone > 0 || taskqueue.taskstaken > 0">{{taskqueue.tasksdone}} / {{taskqueue.totaltasks}} </td>
                          <td v-if="taskqueue.tasksdone > 0 || taskqueue.taskstaken > 0">{{taskqueue.isrush}}</td>
                          <td v-if="taskqueue.tasksdone > 0 || taskqueue.taskstaken > 0">{{taskqueue.delay}}h ago</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="12" class="mt-2">
          <mdb-card>
            <mdb-card-header color="blue accent-3">File Upload Tasks</mdb-card-header>
            <mdb-card-body>
              <mdb-row>
                <mdb-col>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <mdb-tbl hover>
                    <mdb-tbl-head>
                      <tr style="text-align: center;">
                        <th>Task</th>
                        <th>Task Queue Name</th>
                        <th>Method</th>
                        <th>Status</th>
                        <th>Files</th>
                        <th>Added at (minutes ago)</th>
                        <th>Action</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-spinner style="margin-top: 1.5rem;" small color="blue" v-if="isLoadingFileUploadTasks===true" />
                    <mdb-tbl-body v-if="fileUploadTasks && isLoadingFileUploadTasks === false">
                      <tr v-for="(fileUploadTask, index) in fileUploadTasks" :key="index">
                        <td>{{ fileUploadTask.name }}</td>
                        <td>
                          <mdb-btn class="taskqueue-btn" @click="navigateToDetails(fileUploadTask.taskqueueId)">{{ fileUploadTask.taskqueueName }}</mdb-btn>
                        </td>
                        <td>{{ fileUploadTask.body.uploadTo }}</td>
                        <td>{{ fileUploadTask.status }}</td>
                        <td>
                          <mdb-list-group>
                            <mdb-list-group-item
                              v-for="(file, index) in fileUploadTask.body.files"
                              :key="index"
                              class="table-size m-0">
                              <p v-if="file.gdriveMetadata" style="word-break: break-all; white-space: normal;">{{ file.gdriveMetadata.name }}</p>
                              <p v-if="file.frameIoMetadata" style="word-break: break-all; white-space: normal;">{{ file.frameIoMetadata.name }}</p>
                            </mdb-list-group-item>
                          </mdb-list-group>
                        </td>
                        <td>{{ fileUploadTask.minutes_elapsed }}</td>
                        <td v-if="fileUploadTask.status==='INPROGRESS'"><mdb-btn @click="openModalFileUploadTasks=true; fileUploadTaskIdToReset=fileUploadTask.fileuploadtaskId">RESET</mdb-btn></td>
                        <td v-else></td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <mdb-modal :show="openModalFileUploadTasks" @close="openModalFileUploadTasks = false">
      <mdb-modal-header>Are you sure that you want to reset the file upload task?</mdb-modal-header>
      <mdb-modal-body>
        <p>Once you do this, you won't be able to reverse it.</p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click="openModalFileUploadTasks=false">Cancel</mdb-btn>
        <mdb-btn color="success" v-on:click="resetFileUploadTask()">Yes</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
    mdbCard,
    mdbCardHeader,
    mdbCol,
    mdbContainer,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbSpinner,
    mdbModalFooter,
    mdbRow,
    mdbTbl,
    mdbBtn,
    mdbTblBody,
    mdbTblHead,
    mdbCardBody,
    mdbListGroup,
    mdbListGroupItem,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'Home',
  components: {
    Admintopbar,
    Header,
    mdbCard,
    mdbCardHeader,
    mdbCol,
    mdbContainer,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbSpinner,
    mdbRow,
    mdbBtn,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbCardBody,
    mdbListGroup,
    mdbListGroupItem,
  },
  data() {
    return {
      taskqueues: null,
      taskqueuesToFollow: null,
      fileUploadTasks: null,
      openModalFileUploadTasks: false,
      fileUploadTaskIdToReset: null,
      isLoading: false,
      isLoadingFileUploadTasks: false,
      error: '',
      dummy: '',
      breadcrumb: [
        {
          title: 'Home',
          href: null,
        },
      ],
    };
  },
  mounted() {
    this.getTaskqueues();
    this.getTaskqueuesToFollow();
    this.getFileUploadsTasks();
  },
  methods: {
    getTaskqueues() {
      this.isLoading = true;
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/task/ongoing-task-queues', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.taskqueues = response.data.taskqueueinfos;
          }
          this.isLoading = false;
        });
    },

    getTaskqueuesToFollow() {
      const apiCall = new Apicall();
      apiCall.call('GET', 'api/task/get-taskqueues-to-follow', null)
        .then((response) => {
          if (response.status === 'OK') {
           this.taskqueuesToFollow = response.data.taskqueueinfos;
          }
        });
    },
    getFileUploadsTasks() {
      this.isLoadingFileUploadTasks = true;
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/task/todo-and-inprogress-file-upload-tasks', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.fileUploadTasks = response.data.fileUploadTasksInfos;
          }
          this.isLoadingFileUploadTasks = false;
        });
    },
    resetFileUploadTask() {
      const apiCall = new Apicall();

      apiCall.call('POST', 'api/task/reset-file-upload-task', { fileUploadTaskId: this.fileUploadTaskIdToReset })
        .then((response) => {
          if (response.status === 'OK') {
            this.notificationSuccess('File upload task modified successfully!');
            this.openModalFileUploadTasks = false;
            this.getFileUploadsTasks();
          } else {
            this.notificationError(response.msg);
          }
        });
    },
    navigateToDetails(taskqueueId) {
      const resolvedRoute = this.$router.resolve({ name: 'EditTaskQueue', params: { taskqueueid: taskqueueId } });
      window.open(resolvedRoute.href, '_blank');
    },
    nl2br(str) {
      if (str) {
        return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
      }
      return '';
    },
    notificationSuccess(_messsage) {
        this.$notify.success({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
    notificationError(_messsage) {
        this.$notify.error({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
  },
};
</script>

<style scoped>
  .taskqueue-btn {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    color: black;
    padding: 0;
    margin: 0;
    font-size: .9rem !important;
    padding: 0 .35rem;
  }
  .taskqueue-btn:hover {
    color: #FFFFFF !important;
    background-color: #30cfc0 !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15) !important;
  }
</style>
