<template>
  <mdb-container fluid>
    <form @submit="saveTemplate" class="container" @input="() => errorMessage = null">
      <mdb-alert v-if="errorMessage !== null" color="danger">{{errorMessage}}</mdb-alert>
      <mdb-row v-if='success' class='justify-content-around pt-4'>
        <mdb-col md='8'>
          <mdb-alert color='success' >Item Duplicated successfully</mdb-alert>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col>
          <mdb-input v-model="templateName" label="Template Name" />
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col>
          <mdb-btn color="primary" @click="addTask">Add task</mdb-btn>
          <div style="max-height: 50vh; overflow: scroll;" class="mb-1 mt-1 container border p-3">
            <div v-for="(task, i) in tasks" :key="i" >
              <h4>
                Task #{{i + 1}}
                <mdb-btn color="danger" @click="deleteTask(i)">Delete</mdb-btn>
                <mdb-btn v-if="i !== 0" color="primary" @click="moveTask(i, i - 1)">
                  <mdb-icon icon="arrow-up" />
                  Move Up
                </mdb-btn>
                <mdb-btn v-if="i !== tasks.length - 1" color="primary" @click="moveTask(i, i + 1)">
                  <mdb-icon icon="arrow-down" />
                  Move Down
                </mdb-btn>
              </h4>
              <task-template-input :task="task" />
              <hr />
            </div>
          </div>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col>
          <mdb-btn color="success" type="submit" v-if="!loading">Save</mdb-btn>
          <mdb-btn color="primary" v-on:click="openDuplicateModal = true">Duplicate</mdb-btn>
        </mdb-col>
      </mdb-row>
    </form>
    <mdb-modal :show="openDuplicateModal" @close="openDuplicateModal = false" info size="lg">
      <mdb-modal-header>
        <mdb-modal-title>Duplicate Taskqueue Template - {{ templateName }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row v-if='modalerror' class='justify-content-around pt-4'>
          <mdb-col md='12'>
            <mdb-alert color='danger' >{{modalerror}}</mdb-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="newNameDuplicateTaskqueueTemplate" label="Add a Name For Duplicated Taskqueue Template" />
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <div>
          <mdb-btn color="primary" @click="duplicateTaskqueueTemplate">Duplicate</mdb-btn>
          <mdb-btn outline="info" @click="openDuplicateModal=false;">Close</mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbAlert,
  mdbBtn,
  mdbCol,
  mdbContainer,
  mdbIcon,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbRow,
} from 'mdbvue';
import TaskTemplateInput from '@/components/TaskTemplateInput.vue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
    mdbAlert,
    mdbBtn,
    mdbCol,
    mdbIcon,
    mdbContainer,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbRow,
    TaskTemplateInput,
  },
  emits: ['taskqueuetemplate-save'],
  props: {
    taskqueueTemplateId: null,
  },
  data: () => ({
    tasks: [],
    loading: false,
    templateName: '',
    errorMessage: null,
    modalerror: '',
    success: false,
    openDuplicateModal: false,
    newNameDuplicateTaskqueueTemplate: '',
  }),
  beforeMount() {
    if (this.taskqueueTemplateId) {
      this.getTemplateById(this.taskqueueTemplateId);
    }
  },
  methods: {
    /**
     * @param {SubmitEvent} event
     */
    saveTemplate(event) {
      event.preventDefault();
        const apiCall = new Apicall();

      if (this.taskqueueTemplateId) {
        apiCall.call('POST', 'api/admintaskconfig/edit-template', {
          id: this.taskqueueTemplateId,
          templateName: this.templateName,
          tasks: this.tasks,
        }).then((response) => {
          if (response.status === 'OK') {
            this.$emit('taskqueuetemplate-save');
          } else {
            this.errorMessage = response.msg;
          }
        });
      } else {
        apiCall.call('POST', 'api/admintaskconfig/new-template', {
          templateName: this.templateName,
          tasks: this.tasks,
        }).then((response) => {
          if (response.status === 'OK') {
            this.$emit('taskqueuetemplate-save');
          } else {
            this.errorMessage = response.msg;
          }
        });
      }
    },
    addTask() {
      this.tasks.push({
        name: '',
        remun_unit_nb: 0,
        remun_unit: 'MIN',
        remun_unit_rate: 0,
        skills: [],
        instructions: '',
      });
    },
    deleteTask(index) {
      this.tasks.splice(index, 1);
    },
    getTemplateById(id) {
      const apiCall = new Apicall();

      this.loading = true;

      apiCall.call('GET', `api/task/taskqueue-template?id=${id}`, null).then((response) => {
        if (response.status === 'OK') {
          this.loading = false;
          this.templateName = response.data.template.name;
          this.tasks = response.data.template.parsedTemplate.templates;
        }
      });
    },
    duplicateTaskqueueTemplate() {
      const apiCall = new Apicall();
      const self = this;

      if (this.newNameDuplicateTaskqueueTemplate === '') {
        this.modalerror = 'You must add a name for duplicated taskqueue template';
        setTimeout(() => {
          this.modalerror = '';
        }, 2000);
        return;
      }

      apiCall.call('POST', 'api/task/duplicate-taskqueue-template', { taskqueueTemplateId: self.taskqueueTemplateId, newNameDuplicateTaskqueueTemplate: self.newNameDuplicateTaskqueueTemplate }).then((response) => {
        if (response.status === 'OK') {
          self.openDuplicateModal = false;
          self.success = true;
          self.newNameDuplicateTaskType = '';
        }
      });
    },
    moveTask(oldIndex, newIndex) {
        const task = this.tasks[oldIndex];

        this.tasks.splice(oldIndex, 1);
        this.tasks.splice(newIndex, 0, task);
      },
  },
};
</script>

<style>

</style>
